import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';

import { environment } from '../../../../environments/environment.prod';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { SessionService } from '../../../auth/services/session/session.service';
import { AreaCategory } from '../../models/tickets/area-category.model';
import { Employee } from '../../models/tickets/employee.model';
import { Group } from '../../models/tickets/group.model';
import { Media } from '../../models/tickets/media.model';
import { PlagueCategory } from '../../models/tickets/plague-category.model';
import { TicketType } from '../../models/tickets/ticket-type.model';
import { Ticket } from '../../models/tickets/ticket.model';

@Injectable({
  providedIn: 'root'
})
export class TicketsService {
  private _http = inject(HttpClient);
  private _authService = inject(AuthService);
  private _sessionService = inject(SessionService);

  private _baseUrl = `${environment.apiUrl}/administrative`;

  getTicketTypes(): Observable<TicketType[]> {
    const token = this._authService.getToken();
    const headers = {
      Authorization: `Bearer ${token}`
    };

    return this._http.get<TicketType[]>(`${this._baseUrl}/catalogs/ticket-types/`, {
      headers
    });
  }

  getMedia(): Observable<Media[]> {
    const token = this._authService.getToken();
    const headers = {
      Authorization: `Bearer ${token}`
    };

    return this._http.get<Media[]>(`${this._baseUrl}/catalogs/media/`, {
      headers
    });
  }

  getEmployees(): Observable<Employee[]> {
    const token = this._authService.getToken();
    const session = this._sessionService.getSession();

    const headers = {
      Authorization: `Bearer ${token}`
    };

    if (!token || !session) {
      return throwError(() => new Error('Error getting employees'));
    }

    const jobCenterId = session.customer.job_center.id;

    return this._http.get<Employee[]>(`${this._baseUrl}/employees/?job_center=${jobCenterId}`, {
      headers
    });
  }

  getGroups(): Observable<Group[]> {
    const token = this._authService.getToken();
    const headers = {
      Authorization: `Bearer ${token}`
    };

    return this._http.get<Group[]>(`${this._baseUrl}/groups/`, {
      headers
    });
  }

  getAreaCategories(): Observable<AreaCategory[]> {
    const token = this._authService.getToken();
    const headers = {
      Authorization: `Bearer ${token}`
    };

    return this._http.get<AreaCategory[]>(`${this._baseUrl}/catalogs/area-categories/`, {
      headers
    });
  }

  getPlagueCategories(): Observable<PlagueCategory[]> {
    const token = this._authService.getToken();
    const headers = {
      Authorization: `Bearer ${token}`
    };

    return this._http.get<PlagueCategory[]>(`${this._baseUrl}/catalogs/plague-categories/`, {
      headers
    });
  }

  getTickets(page: number = 1, pageSize: number = 10): Observable<any> {
    const token = this._authService.getToken();
    const session = this._sessionService.getSession();

    const headers = {
      Authorization: `Bearer ${token}`
    };

    if (!token || !session) {
      return throwError(() => new Error('Error getting tickets'));
    }

    const customerId = session.customer.id;

    return this._http.get<any>(`${this._baseUrl}/tickets/?customer=${customerId}&page=${page}&page_size=${pageSize}`, {
      headers
    });
  }

  createTicket(
    comments: string,
    ticketType: string,
    areaCategory: string,
    plagueCategory: string,
    closeDate: string
  ): Observable<Ticket> {
    const token = this._authService.getToken();
    const session = this._sessionService.getSession();

    const headers = {
      Authorization: `Bearer ${token}`
    };

    if (!token || !session) {
      return throwError(() => new Error('Error al guardar el ticket'));
    }

    const customerId = session.customer.id;
    const jobCenterId = session.customer.job_center.id;

    const body = {
      ticket_type: ticketType,
      customer: customerId,
      close_date: closeDate,
      area_category: areaCategory,
      plague_category: plagueCategory,
      comments,
      job_center: jobCenterId
    };

    return this._http.post<Ticket>(`${this._baseUrl}/tickets/`, body, {
      headers
    });
  }
}
