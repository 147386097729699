<app-layout>
  <div class="home-element">
    <h2>
      <i class="pi pi-lightbulb"></i> Inspecciones
    </h2>

    <section class="table-container">
      <p-table
        #dt2
        [value]="services"
        dataKey="id"
        [rows]="10"
        [rowsPerPageOptions]="[10, 25, 50]"
        [loading]="loadingServices"
        [paginator]="true"
        styleClass="p-datatable-sm"
        [globalFilterFields]="['folio', 'event', 'employee', 'customer_name', 'customer_address', 'no_of_inspections']"
        [tableStyle]="{ 'min-width': '125rem' }"
      >
        <ng-template pTemplate="header">
          <tr>
            <th style="width:10%" pSortableColumn="folio" class="bg-gray-100 border-bottom">
              <p class="th-inside">
                Folio <p-sortIcon field="folio" />
              </p>
            </th>

            <th style="width:18%" pSortableColumn="event" class="bg-gray-100 border-bottom">
              <p class="th-inside">
                Servicio <p-sortIcon field="event" />
              </p>
            </th>

            <th style="width:18%" pSortableColumn="date" class="bg-gray-100 border-bottom">
              <p class="th-inside">
                Fecha / Hora de servicio <p-sortIcon field="date" />
              </p>
            </th>

            <th style="width:18%" pSortableColumn="employee" class="bg-gray-100 border-bottom">
              <p class="th-inside">
                Técnico responsable <p-sortIcon field="employee" />
              </p>
            </th>

            <th style="width:18%" pSortableColumn="customer_name" class="bg-gray-100 border-bottom">
              <p class="th-inside">
                Cliente <p-sortIcon field="customer_name" />
              </p>
            </th>

            <th style="width:18%" pSortableColumn="customer_address" class="bg-gray-100 border-bottom">
              <p class="th-inside">
                Dirección <p-sortIcon field="customer_address" />
              </p>
            </th>
          </tr>

          <tr>
            <th>
              <p-columnFilter
                type="text"
                field="folio"
                placeholder="Buscar por folio"
                ariaLabel="Buscar por folio"
                matchMode="contains"
              />
            </th>

            <th>
              <p-columnFilter
                type="text"
                field="event"
                placeholder="Buscar por servicio"
                ariaLabel="Buscar por servicio"
                matchMode="contains"
              />
            </th>

            <th></th>

            <th>
              <p-columnFilter
                type="text"
                field="employee"
                placeholder="Buscar por técnico"
                ariaLabel="Buscar por técnico"
                matchMode="contains"
              />
            </th>

            <th>
              <p-columnFilter
                type="text"
                field="customer_name"
                placeholder="Buscar por cliente"
                ariaLabel="Buscar por cliente"
                matchMode="contains"
              />
            </th>

            <th>
              <p-columnFilter
                type="text"
                field="customer_address"
                placeholder="Buscar por dirección"
                ariaLabel="Buscar por dirección"
                matchMode="contains"
              />
            </th>
          </tr>
        </ng-template>

        <ng-template pTemplate="body" let-service>
          <tr>
            <td>
              <span
                class="folio"
                (click)="handleOpenModal(service)"
              >
                {{ service.folio }}
              </span>
            </td>

            <td>
              {{ service.event }}
            </td>

            <td>
              {{ service.date | date: 'dd/MM/yyyy' }}
              <br />
              {{ service.hour }}
            </td>

            <td>
              {{ service.employee }}
            </td>

            <td>
              {{ service.customer_name }}
            </td>

            <td>
              {{ service.customer_address }}
            </td>
          </tr>
        </ng-template>

        <ng-template pTemplate="emptymessage">
          <tr>
            <td colspan="9">
              <p class="emptymessage">No se encontraron inspecciones</p>
            </td>
          </tr>
        </ng-template>
      </p-table>
    </section>

    <p-dialog
      [modal]="true"
      [(visible)]="isModalVisible"
      [style]="{ width: '80%' }"
    >
      <ng-template pTemplate="header">
        <h3>
          Detalle de la Inspección
        </h3>
      </ng-template>

      @if (activeService) {
        <div class="modal-header">
          <article>
            <p>
              <span class="service-data">Folio del servicio: </span>
              {{ activeService.folio }}
            </p>

            <p>
              <span class="service-data">Técnico aplicador: </span>
              {{ activeService.employee }}
            </p>
          </article>

          <article>
            <p>
              <span class="service-data">Fecha: </span>
              {{ activeService.date | date: 'dd/MM/yyyy' }}
            </p>

            <p>
              <span class="service-data">Hora: </span>
              {{ activeService.hour }}
            </p>
          </article>
        </div>
      }

      <div>
        <p-table
          #dt2
          [value]="inspections"
          dataKey="id"
          [rows]="10"
          [rowsPerPageOptions]="[10, 25, 50]"
          [loading]="loadingInspections"
          [paginator]="true"
          styleClass="p-datatable-sm"
          [globalFilterFields]="['zone', 'nesting_area', 'plague', 'plagueCategory', 'infestationDegree', 'evidence_type', 'life_cycle_plague']"
          [tableStyle]="{ 'min-width': '150rem' }"
        >
          <ng-template pTemplate="header">
            <tr>
              <th style="width:10%" pSortableColumn="nesting_area" class="bg-gray-100 border-bottom">
                <p class="th-inside">
                  Área <p-sortIcon field="nesting_area" />
                </p>
              </th>

              <th style="width:15%" pSortableColumn="plague" class="bg-gray-100 border-bottom">
                <p class="th-inside">
                  Plaga <p-sortIcon field="plague" />
                </p>
              </th>

              <th style="width:15%" pSortableColumn="plagueCategory" class="bg-gray-100 border-bottom">
                <p class="th-inside">
                  Categoría de plaga <p-sortIcon field="plagueCategory" />
                </p>
              </th>

              <th style="width:15%" pSortableColumn="infestationDegree" class="bg-gray-100 border-bottom">
                <p class="th-inside">
                  Grado de infestación <p-sortIcon field="infestationDegree" />
                </p>
              </th>

              <th style="width:15%" pSortableColumn="evidence_type" class="bg-gray-100 border-bottom">
                <p class="th-inside">
                  Tipo de evidencia <p-sortIcon field="evidence_type" />
                </p>
              </th>

              <th style="width:15%" pSortableColumn="life_cycle_plague" class="bg-gray-100 border-bottom">
                <p class="th-inside">
                  Ciclo de vida de la plaga <p-sortIcon field="life_cycle_plague" />
                </p>
              </th>

              <th style="width:15%" class="bg-gray-100 border-bottom">
                <p class="th-inside">
                  Fotos
                </p>
              </th>
            </tr>

            <tr>
              <th>
                <p-columnFilter
                  type="text"
                  field="nesting_area"
                  placeholder="Buscar por área"
                  ariaLabel="Buscar por área"
                  matchMode="contains"
                />
              </th>

              <th>
                <p-columnFilter
                  type="text"
                  field="plague"
                  placeholder="Buscar por plaga"
                  ariaLabel="Buscar por plaga"
                  matchMode="contains"
                />
              </th>

              <th>
                <p-columnFilter
                  type="text"
                  field="plagueCategory"
                  placeholder="Buscar por categoría de plaga"
                  ariaLabel="Buscar por categoría de plaga"
                  matchMode="contains"
                />
              </th>

              <th>
                <p-columnFilter
                  type="text"
                  field="infestationDegree"
                  placeholder="Buscar por grado de infestación"
                  ariaLabel="Buscar por grado de infestación"
                  matchMode="contains"
                />
              </th>

              <th>
                <p-columnFilter
                  type="text"
                  field="evidence_type"
                  placeholder="Buscar por tipo de evidencia"
                  ariaLabel="Buscar por tipo de evidencia"
                  matchMode="contains"
                />
              </th>

              <th>
                <p-columnFilter
                  type="text"
                  field="life_cycle_plague"
                  placeholder="Buscar por ciclo de vida de la plaga"
                  ariaLabel="Buscar por ciclo de vida de la plaga"
                  matchMode="contains"
                />
              </th>

              <th></th>
            </tr>
          </ng-template>

          <ng-template pTemplate="body" let-inspection>
            <tr>
              <td>
                {{ inspection.nesting_area }}
              </td>

              <td>
                {{ inspection.plague }}
              </td>

              <td>
                {{ inspection.plagueCategory }}
              </td>

              <td>
                {{ inspection.infestationDegree }}
              </td>

              <td>
                {{ inspection.evidence_type }}
              </td>

              <td>
                {{ inspection.life_cycle_plague }}
              </td>

              <td class="photos-container">
                @if (inspection.photos.length > 0) {
                  @for (photo of inspection.photos; track $index) {
                    <img
                      [src]="photo"
                      alt="Foto de inspección"
                      class="photo"
                      width="100"
                    />
                  }
                } @else {
                  No hay fotos
                }
              </td>
            </tr>
          </ng-template>

          <ng-template pTemplate="emptymessage">
            <tr>
              <td colspan="9">
                <p class="emptymessage">No se encontraron inspecciones</p>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </p-dialog>
  </div>
</app-layout>
