import { CommonModule } from '@angular/common';
import { Component, ElementRef, inject, OnInit, ViewChild } from '@angular/core';
import { Router, RouterLink, RouterModule } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { MenuModule } from 'primeng/menu';

import { Session } from '../../../../auth/models/session.model';
import { AuthService } from '../../../../auth/services/auth/auth.service';
import { LayoutService } from '../../../services/layout/layout.service';

@Component({
  selector: 'app-topbar',
  standalone: true,
  imports: [
    RouterModule,
    RouterLink,
    CommonModule,
    MenuModule
  ],
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss'
})
export class TopbarComponent implements OnInit {
  private _layoutService = inject(LayoutService);
  private _authService = inject(AuthService);
  private _router = inject(Router);

  @ViewChild('searchinput') searchInput!: ElementRef;
  @ViewChild('menubutton') menuButton!: ElementRef;

  public menu: MenuItem[] = [];
  public searchActive: boolean = false;
  public profile: Session | null = null;

  public menuItems = [
    {
      items: [
        { label: 'Cambiar contraseña', icon: 'pi pi-lock', command: this.changePassword.bind(this) },
        { label: 'Cerra sesión', icon: 'pi pi-power-off', command: this.logout.bind(this) },
      ],
    },
  ];

  ngOnInit(): void {
    this.profile = this._authService.activeUser;
  }

  onMenuButtonClick() {
    this._layoutService.onMenuToggle();
  }

  removeTab(event: MouseEvent, item: MenuItem, index: number) {
    this._layoutService.onTabClose(item, index);
    event.preventDefault();
  }

  get layoutTheme(): string {
    return this._layoutService.config.layoutTheme;
  }

  get colorScheme(): string {
    return this._layoutService.config.colorScheme;
  }

  get tabs(): MenuItem[] {
    return this._layoutService.tabs;
  }

  logout(): void {
    this._authService.logout();
  }

  changePassword(): void {
    this._router.navigate(['/change-password']);
  }
}
