import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { environment } from '../../../../environments/environment.prod';
import { AuthService } from '../../../auth/services/auth/auth.service';
import { SessionService } from '../../../auth/services/session/session.service';
import { DocumentInStorage } from '../../models/documents.model';

@Injectable({
  providedIn: 'root'
})
export class MipService {
  private _http = inject(HttpClient);
  private _authService = inject(AuthService);
  private _sessionService = inject(SessionService);

  private _baseUrl = `${environment.apiUrl}/administrative/customers`;

  getCalendarDocument(
    fromDate: string,
    toDate: string,
    OR: boolean = false,
    RP: boolean = false,
    IN: boolean = false,
    CT: boolean = false,
    QO: boolean = false
  ): Observable<DocumentInStorage> {
    const token = this._authService.getToken()!;
    const session = this._sessionService.getSession();
    const customerId = session!.customer.id;

    let documentUrl = `${this._baseUrl}/${customerId}/FileMip/?from_time=${fromDate}&to_time=${toDate}`;

    if (OR) documentUrl += '&OR=1';
    else documentUrl += '&OR=0';

    if (RP) documentUrl += '&RP=1';
    else documentUrl += '&RP=0';

    if (IN) documentUrl += '&IN=1';
    else documentUrl += '&IN=0';

    if (CT) documentUrl += '&CT=1';
    else documentUrl += '&CT=0';

    if (QO) documentUrl += '&QO=1';
    else documentUrl += '&QO=0';

    return this._http.get<DocumentInStorage>(documentUrl, { headers: { Authorization: `Bearer ${token}` } });
  }
}
