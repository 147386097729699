import { Component, inject } from '@angular/core';
import { Router, RouterLink } from '@angular/router';

import { LayoutComponent } from '../../../shared/layouts/layout/layout.component';
import { Event as EventType } from '../../models/events.model';
import { Task } from '../../models/mip/task.model';
import { Ticket } from '../../models/tickets/ticket.model';
import { StatsService } from '../../services/stats/stats.service';
import { LayoutService } from '../../../shared/services/layout/layout.service';

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    LayoutComponent,
    RouterLink
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent {
  private _statsService = inject(StatsService);
  private _router = inject(Router);
  private _layoutService = inject(LayoutService);

  public openTickets: Ticket[] = [];
  public pendingServices: EventType[] = [];
  public tasks: Task[] = [];

  public ticketsPage: number = 1;
  public servicesPage: number = 1;
  public tasksPage: number = 1;

  constructor() {
    this.loadOpenTickets();
    this.loadPendingServices();
    this.loadTasks();
  }

  get colorScheme(): string {
    return this._layoutService.config.colorScheme;
  }

  loadOpenTickets(page: number = 1): void {
    this._statsService.getOpenTickets(page).subscribe({
      next: (data: any) => {
        const ticketsData = data.items;
        this.openTickets = [...this.openTickets, ...ticketsData];
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  loadPendingServices(page: number = 1): void {
    const today = new Date();

    const oneMonthAgo = new Date(today);
    oneMonthAgo.setMonth(today.getMonth() - 1);

    const oneMonthAhead = new Date(today);
    oneMonthAhead.setMonth(today.getMonth() + 1);

    this._statsService.getPendingServices(page).subscribe({
      next: (data: any) => {
        const services = data.items;

        const servicesData = services.filter((service: EventType) => {
          const initialDate = new Date(service.initial_date);
          const finalDate = new Date(service.final_date);

          const isStatusNotCompleted = service.status.name !== "Completado";
          const isInDateRange = initialDate >= oneMonthAgo && finalDate <= oneMonthAhead;

          return isStatusNotCompleted && isInDateRange;
        });

        this.pendingServices = [...this.pendingServices, ...servicesData];
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  loadTasks(page: number = 1): void {
    this._statsService.getTasks(page).subscribe({
      next: (data: any) => {
        const tasksData = data.items;
        this.tasks = [...this.tasks, ...tasksData];
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  handleNavToServices(): void {
    this._router.navigate(['/services']);
  }

  handleNavToTickets(): void {
    this._router.navigate(['/tracings']);
  }

  handleNavToTasks(): void {
    this._router.navigate(['/']);
  }

  handleRefreshTickets(): void {
    this.ticketsPage += 1;
    this.loadOpenTickets(this.ticketsPage);
  }

  handleRefreshServices(): void {
    this.servicesPage += 1;
    this.loadPendingServices(this.servicesPage);
  }

  handleRefreshTasks(): void {
    this.tasksPage += 1;
    this.loadTasks(this.tasksPage);
  }
}
