<app-layout>
  <div class="home-element">
    <h1 class="title">
      <i class="pi pi-home"></i> Bienvenido a PestwareApp
    </h1>

    <div class="grid mt-5 mb-5">
      <div class="col-12 lg:col-6 xl:col-4">
        <div class="card p-0 overflow-hidden flex flex-column">
          <div class="flex align-items-center p-3">
            <i class="pi pi-calendar text-6xl text-orange-500"></i>

            <div class="ml-3">
              <span class="text-orange-500 block white-space-nowrap">
                SERVICIOS PENDIENTES
              </span>

              <span class="text-orange-500 block text-4xl font-bold">
                {{ pendingServices.length }}
              </span>
            </div>
          </div>

          <img
            [src]="'assets/demo/images/dashboard/locations' + (colorScheme === 'light' ? '' : '-dark') + '.svg'"
            class="w-full"
            alt="locations"
          >
        </div>
      </div>

      <div class="col-12 lg:col-6 xl:col-4">
        <div class="card p-0 overflow-hidden flex flex-column">
          <div class="flex align-items-center p-3">
            <i class="pi pi-ticket text-6xl text-green-500"></i>

            <div class="ml-3">
              <span class="text-green-500 block white-space-nowrap">
                TICKETS
              </span>

              <span class="text-green-500 block text-4xl font-bold">
                {{ openTickets.length }}
              </span>
            </div>
          </div>

          <img
            [src]="'assets/demo/images/dashboard/rate' + (colorScheme === 'light' ? '' : '-dark') + '.svg'"
            class="w-full"
            alt="conversion"
          >
        </div>
      </div>

      <div class="col-12 lg:col-6 xl:col-4">
        <div class="card h-full p-0 overflow-hidden flex flex-column">
          <div class="flex align-items-center p-3">
            <i class="pi pi-list text-6xl text-purple-500"></i>

            <div class="ml-3">
              <span class="text-purple-500 block white-space-nowrap">
                TAREAS
              </span>

              <span class="text-purple-500 block text-4xl font-bold">
                {{ tasks.length }}
              </span>
            </div>
          </div>

          <img
            [src]="'assets/demo/images/dashboard/interactions' + (colorScheme === 'light' ? '' : '-dark') + '.svg'"
            class="w-full mt-auto"
            alt="interactions"
          >
        </div>
      </div>
    </div>

    <section class="cards-section">
      <div class="card-item">
        <div class="card-header">
          <h3>
            Servicios pendientes
          </h3>

          <span
            class="card-refresh"
            (click)="handleRefreshServices()"
          >
            <i class="pi pi-refresh" style="color: #25292e"></i>
          </span>
        </div>

        @if (pendingServices.length > 0) {
          @for (service of pendingServices; track service.id) {
            <a
              [routerLink]="['/event-details', service.id]"
              class="service"
            >
              <span class="service-folio">
                {{ service.folio }}
              </span>

              <div class="service-body">
                <p class="service-title">
                  {{ service.folio }} - {{ service.title }}
                </p>
              </div>
            </a>
          }
        } @else {
          <p class="no-items">
            No hay servicios pendientes
          </p>
        }

        <button
          class="button"
          (click)="handleNavToServices()"
        >
          <span>Ver todos</span>
          <i class="pi pi-arrow-right" style="font-size: 1rem"></i>
        </button>
      </div>

      <div class="card-item">
        <div class="card-header">
          <h3>
            Tickets abiertos
          </h3>

          <span
            class="card-refresh"
            (click)="handleRefreshTickets()"
          >
            <i class="pi pi-refresh" style="color: #25292e"></i>
          </span>
        </div>

        @if (openTickets.length > 0) {
          @for (ticket of openTickets; track ticket.id) {
            <article class="ticket">
              <span class="ticket-folio">
                {{ ticket.ticket_type.folio_key_setting }}
              </span>

              <div class="ticket-body">
                <p class="ticket-title">
                  {{ ticket.folio }} - {{ ticket.ticket_type.name }}
                </p>

                <p class="ticket-customer">
                  {{ ticket.employee.name }}
                </p>

                <p class="ticket-description">
                  {{ ticket.comments }}
                </p>
              </div>
            </article>
          }
        } @else {
          <p class="no-items">
            No hay tickets abiertos
          </p>
        }

        <button
          class="button"
          (click)="handleNavToTickets()"
        >
          <span>Ver todos</span>
          <i class="pi pi-arrow-right" style="font-size: 1rem"></i>
        </button>
      </div>

      <div class="card-item">
        <div class="card-header">
          <h3>
            Tareas
          </h3>

          <span
            class="card-refresh"
            (click)="handleRefreshTasks()"
          >
            <i class="pi pi-refresh" style="color: #25292e"></i>
          </span>
        </div>

        @if (tasks.length > 0) {
          @for (task of tasks; track task.id) {
            <article class="task">
              <span class="task-folio">
                {{ task.folio }}
              </span>

              <div class="task-body">
                <p class="task-title">
                  {{ task.folio }} - {{ task.title }}
                </p>

                <p class="task-description">
                  {{ task.description }}
                </p>
              </div>
            </article>
          }
        } @else {
          <p class="no-items">
            No hay tareas
          </p>
        }

        <button
          class="button"
          (click)="handleNavToTasks()"
        >
          <span>Ver todas</span>
          <i class="pi pi-arrow-right" style="font-size: 1rem"></i>
        </button>
      </div>
    </section>
  </div>
</app-layout>
