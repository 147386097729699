import { Component, ElementRef, inject, ViewChild } from '@angular/core';

import { LayoutService } from '../../../services/layout/layout.service';
import { MenuComponent } from '../menu/menu.component';

@Component({
  selector: 'app-sidebar',
  standalone: true,
  imports: [
    MenuComponent
  ],
  templateUrl: './sidebar.component.html',
  styleUrl: './sidebar.component.scss'
})
export class SidebarComponent {
  private _layoutService = inject(LayoutService);

  @ViewChild('menuContainer') menuContainer!: ElementRef;

  constructor(public el: ElementRef) { }
}
