import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { CalendarModule } from 'primeng/calendar';
import { CheckboxModule } from 'primeng/checkbox';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';

import { environment } from '../../../../environments/environment.prod';
import { LayoutComponent } from '../../../shared/layouts/layout/layout.component';
import { SafeUrlPipe } from '../../pipes/safe-url/safe-url.pipe';
import { MipService } from '../../services/mip/mip.service';

@Component({
  selector: 'app-mip-folders',
  standalone: true,
  imports: [
    LayoutComponent,
    CalendarModule,
    ReactiveFormsModule,
    DropdownModule,
    CheckboxModule,
    DialogModule,
    SafeUrlPipe
  ],
  templateUrl: './mip-folders.component.html',
  styleUrl: './mip-folders.component.scss'
})
export class MipFoldersComponent {
  private _mipService = inject(MipService);
  public reportsForm: FormGroup;

  public documentUrl: string | null = null;
  public isModalLoading: boolean = false;
  public isModalVisible: boolean = false;
  public iframeModalVisible: boolean = false;

  constructor(private _formBuilder: FormBuilder) {
    this.reportsForm = this._formBuilder.group({
      initialDate: [null, Validators.required],
      finalDate: [null, Validators.required],
      OR: [[]],
      RP: [[]],
      IN: [[]],
      CT: [[]],
      QO: [[]]
    });
  }

  onSubmit(event: Event): void {
    event.preventDefault();

    if (this.reportsForm.valid) {
      this.isModalLoading = true;
      this.isModalVisible = false;
      this.iframeModalVisible = true;

      const initialDate = this.reportsForm.get('initialDate')?.value;
      const formattedInitialDate = initialDate ? initialDate.toISOString().split('T')[0] : null;

      const finalDate = this.reportsForm.get('finalDate')?.value;
      const formattedFinalDate = finalDate ? finalDate.toISOString().split('T')[0] : null;

      const OR = this.reportsForm.get('OR')?.value;
      const RP = this.reportsForm.get('RP')?.value;
      const IN = this.reportsForm.get('IN')?.value;
      const CT = this.reportsForm.get('CT')?.value;
      const QO = this.reportsForm.get('QO')?.value;

      const orIsChecked = OR && OR.length > 0;
      const rpIsChecked = RP && RP.length > 0;
      const inIsChecked = IN && IN.length > 0;
      const ctIsChecked = CT && CT.length > 0;
      const qoIsChecked = QO && QO.length > 0;

      this._mipService.getCalendarDocument(
        formattedInitialDate,
        formattedFinalDate,
        orIsChecked,
        rpIsChecked,
        inIsChecked,
        ctIsChecked,
        qoIsChecked
      ).subscribe((response) => {
        this.documentUrl = environment.documentStorageUrl + response.data;
        this.isModalLoading = false;
      });
    } else {
      this.reportsForm.markAllAsTouched();
    }
  }
}
