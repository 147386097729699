<div class="home-element">
  <h2>
    <i class="pi pi-calendar"></i> Mis Servicios Programados
  </h2>

  <p-table
    #dt2
    [value]="services"
    dataKey="id"
    [rows]="rows"
    [rowsPerPageOptions]="[10, 25, 50]"
    [totalRecords]="totalRecords"
    [lazy]="true"
    [showCurrentPageReport]="true"
    [loading]="loading"
    [paginator]="false"
    sortMode="multiple"
    styleClass="p-datatable-sm"
    [globalFilterFields]="['folio', 'title', 'initial_date', 'status.name', 'employee.name']"
    [tableStyle]="{ 'min-width': '80rem' }"
  >
    <ng-template pTemplate="caption">
      <div class="flex">
        <p-iconField iconPosition="left" class="ml-auto">
          <p-inputIcon>
            <i class="pi pi-search"></i>
          </p-inputIcon>

          <input
            pInputText
            type="text"
            (input)="filterServices($event)"
            placeholder="Buscar"
          />
        </p-iconField>
      </div>
    </ng-template>

    <ng-template pTemplate="header">
      <tr>
        <th style="width:15%" pSortableColumn="folio" class="bg-gray-100 border-bottom">
          <p class="th-inside">
            Folio <p-sortIcon field="folio" />
          </p>
        </th>

        <th style="width:20%" pSortableColumn="title" class="bg-gray-100 border-bottom">
          <p class="th-inside">
            Nombre <p-sortIcon field="title" />
          </p>
        </th>

        <th style="width:15%" pSortableColumn="initial_date" class="bg-gray-100 border-bottom">
          <p class="th-inside">
            Fecha y hora de servicio <p-sortIcon field="initial_date" />
          </p>
        </th>

        <th style="width:15%" pSortableColumn="status.name" class="bg-gray-100 border-bottom">
          <p class="th-inside">
            Estado <p-sortIcon field="status.name" />
          </p>
        </th>

        <th style="width:20%" pSortableColumn="employee.name" class="bg-gray-100 border-bottom">
          <p class="th-inside">
            Empleado <p-sortIcon field="employee.name" />
          </p>
        </th>

        <th style="width:15%" class="bg-gray-100 border-bottom">
          <p class="th-inside">
            Opciones
          </p>
        </th>

      </tr>

      <tr>
        <th>
          <p-columnFilter
            type="text"
            field="folio"
            placeholder="Folio..."
            ariaLabel="Folio..."
          />
        </th>

        <th>
          <p-columnFilter
            type="text"
            field="title"
            placeholder="Nombre..."
            ariaLabel="Nombre..."
          />
        </th>

        <th>
          <p-columnFilter
            type="text"
            field="initial_date"
            placeholder="Fecha y hora..."
            ariaLabel="Fecha y hora..."
          />
        </th>

        <th>
          <p-columnFilter field="status.name" matchMode="equals" [showMenu]="false">
            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
              <p-dropdown
                [options]="statuses"
                (onChange)="filter($event.value)"
                placeholder="Estado..."
                [showClear]="true"
              >
                <ng-template let-option pTemplate="item">
                  <span class="state-tag">
                    {{ option.value }}
                  </span>
                </ng-template>
              </p-dropdown>
            </ng-template>
          </p-columnFilter>
        </th>

        <th>
          <p-columnFilter
            type="text"
            field="employee.name"
            placeholder="Empleado..."
            ariaLabel="Empleado..."
          />
        </th>

        <th></th>
      </tr>
    </ng-template>

    <ng-template pTemplate="body" let-service>
      <tr>
        <td>{{ service.folio }}</td>
        <td>{{ service.title }}</td>

        <td class="date-in-table">
          {{ service.initial_date | date: 'dd/MM/yyyy' }}
          <br />
          {{ formatTime(service.initial_hour) }}
        </td>

        <td>
          <p-tag
            [value]="service.status.name"
            [severity]="getSeverity(service.status.name)"
          />
        </td>

        <td>{{ service.employee.name }}</td>

        <td class="options">
          @if (service.status.name === 'Completado' || service.status.name === 'Finalizado' || service.status.name === 'Sincronizado') {
            <div>
              <p-button
                label="Ver servicio"
                size="small"
                (onClick)="handleSeeDetails(service)"
              />
            </div>

            <div class="options-documents">
              <p-toast />

              <p-button
                size="small"
                [icon]="loadingServiceOrder[service.id] ? 'pi pi-spin pi-spinner' : 'pi pi-folder-open'"
                pTooltip="Orden de servicio"
                tooltipPosition="top"
                severity="warning"
                [disabled]="loadingServiceOrder[service.id]"
                (onClick)="handleServiceOrderDocument(service)"
              />

              <p-button
                size="small"
                [icon]="loadingServiceCertificate[service.id] ? 'pi pi-spin pi-spinner' : 'pi pi-folder-open'"
                pTooltip="Certificado de servicio"
                tooltipPosition="top"
                severity="warning"
                [disabled]="loadingServiceCertificate[service.id]"
                (onClick)="handleServiceOrderCertificate(service)"
              />
            </div>
          } @else {
            <p class="no-options">
              No hay opciones disponibles
            </p>
          }
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate="emptymessage">
      <tr>
        <td colspan="2">
          No se encontraron servicios
        </td>
      </tr>
    </ng-template>
  </p-table>

  <p-paginator
    [first]="firstPage"
    [rows]="rows"
    [rowsPerPageOptions]="[10, 20, 30, 50]"
    [totalRecords]="totalRecords"
    (onPageChange)="pageChange($event)"
    currentPageReportTemplate="{currentPage} de {totalPages}"
    [showJumpToPageDropdown]="true"
    [showPageLinks]="true"
    [showJumpToPageInput]="true"
  ></p-paginator>
</div>
