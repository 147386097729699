import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { forkJoin } from 'rxjs';
import { PaginatorModule } from 'primeng/paginator';

import { LayoutComponent } from '../../../shared/layouts/layout/layout.component';
import { AreaCategory } from '../../models/tickets/area-category.model';
import { PlagueCategory } from '../../models/tickets/plague-category.model';
import { TicketType } from '../../models/tickets/ticket-type.model';
import { Ticket } from '../../models/tickets/ticket.model';
import { TicketsService } from '../../services/tickets/tickets.service';

interface Filter {
  label: string;
  value: string;
}

@Component({
  selector: 'app-tracings',
  standalone: true,
  imports: [
    AutoCompleteModule,
    ButtonModule,
    CalendarModule,
    CommonModule,
    DialogModule,
    DropdownModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    InputTextareaModule,
    LayoutComponent,
    MultiSelectModule,
    ReactiveFormsModule,
    TableModule,
    TagModule,
    ToastModule,
    TooltipModule,
    PaginatorModule
  ],
  providers: [MessageService],
  templateUrl: './tracings.component.html',
  styleUrl: './tracings.component.scss'
})
export class TracingsComponent implements OnInit {
  public tracingForm: FormGroup;
  private _ticketsService = inject(TicketsService);

  public tickets: Ticket[] = [];
  public comments: string = '';
  public ticketsTypes: TicketType[] = [];
  public areaCategories: AreaCategory[] = [];
  public plagueCategories: PlagueCategory[] = [];
  public filterTicketsTypes: any[] = [];
  public filterAreaCategories: any[] = [];
  public filterPlagueCategories: any[] = [];

  public loading: boolean = false;
  public isModalVisible: boolean = false;

  public ticketTypeFilter: Filter[] = [];
  public statusFilter: Filter[] = [];
  public mediaFilter: Filter[] = [];
  public groupFilter: Filter[] = [];
  public areaCategoryFilter: Filter[] = [];
  public plagueCategoryFilter: Filter[] = [];

  public totalRecords: number = 0;
  public currentPage: number = 1;
  public rows: number = 10;
  public firstPage: number = 0;

  constructor(
    private _messageService: MessageService,
    private _formBuilder: FormBuilder
  ) {
    this.tracingForm = this._formBuilder.group({
      comments: ['', [Validators.required]],
      ticketType: ['', [Validators.required]],
      areaCategory: ['', [Validators.required]],
      plagueCategory: ['', [Validators.required]],
    });
  }

  ngOnInit(): void {
    this.loadTickets(this.currentPage, this.rows);
    this.loadData();
  }

  loadTickets(page: number, pageSize: number): void {
    this.loading = true;

    this._ticketsService.getTickets(page, pageSize).subscribe((data: any) => {
      this.tickets = data.items;
      this.totalRecords = data.total;
      this.currentPage = data.page;
      this.loading = false;
    });
  }

  loadData(): void {
    forkJoin({
      ticketTypes: this._ticketsService.getTicketTypes(),
      areaCategories: this._ticketsService.getAreaCategories(),
      plagueCategories: this._ticketsService.getPlagueCategories(),
    }).subscribe({
      next: (results: any) => {
        this.ticketsTypes = results.ticketTypes;
        this.areaCategories = results.areaCategories;
        this.plagueCategories = results.plagueCategories;

        this.statusFilter = [
          { label: 'Creada', value: 'Creada' },
          { label: 'Cerrada', value: 'Cerrada' },
        ];

        this.ticketTypeFilter = results.ticketTypes.map((type: any) => ({
          label: type.name,
          value: type.name
        }));

        this.areaCategoryFilter = results.areaCategories.map((category: any) => ({
          label: category.name,
          value: category.name
        }));

        this.plagueCategoryFilter = results.plagueCategories.map((category: any) => ({
          label: category.name,
          value: category.name
        }));
      },
      error: (error: any) => {
        console.error('Error al cargar los datos:', error);
      }
    });
  }

  filterTickets(event: any) {
    this.filterTicketsTypes = this.ticketsTypes.filter((type: any) => {
      return type.name.toLowerCase().includes(event.query.toLowerCase());
    });
  }

  filterArea(event: any) {
    this.filterAreaCategories = this.areaCategories.filter((category: any) => {
      return category.name.toLowerCase().includes(event.query.toLowerCase());
    });
  }

  filterPlague(event: any) {
    this.filterPlagueCategories = this.plagueCategories.filter((category: any) => {
      return category.name.toLowerCase().includes(event.query.toLowerCase());
    });
  }

  handleSave(event: Event): void {
    event.preventDefault();

    const today = new Date();
    const todayFormatted = `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`;

    if (this.tracingForm.valid) {
      this._ticketsService.createTicket(
        this.tracingForm.value.comments,
        this.tracingForm.value.ticketType.id,
        this.tracingForm.value.areaCategory.id,
        this.tracingForm.value.plagueCategory.id,
        todayFormatted
      ).subscribe({
        next: (data: Ticket) => {
          this._messageService.add({
            severity: 'success',
            summary: '¡Éxito!',
            detail: '¡El ticket se ha creado correctamente!'
          });

          this.loadTickets(this.currentPage, this.rows);
          this.isModalVisible = false;
        },
        error: (error: any) => {
          this._messageService.add({
            severity: 'error',
            summary: 'Error',
            detail: 'Hubo un problema al crear el ticket. Por favor, inténtelo de nuevo.'
          });

          console.error('Error al crear el ticket:', error);
        }
      });

      this.isModalVisible = false;
    } else {
      this.tracingForm.markAllAsTouched();
    }
  }

  hasErrors(field: string, typeError: string) {
    return this.tracingForm.get(field)?.hasError(typeError) && this.tracingForm.get(field)?.touched;
  }

  pageChange(event: any) {
    const newPage = event.first / event.rows;
    this.firstPage = event.first;

    this.loadTickets(newPage, event.rows);
  }
}
