import { Component, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { BaseChartDirective } from 'ng2-charts';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { forkJoin } from 'rxjs';

import { LayoutComponent } from '../../../shared/layouts/layout/layout.component';
import { generateRandomColor } from '../../../shared/utils/random-color.util';
import { ConditionReportResponse } from '../../models/reports/condition-report.model';
import { InspectionReportResponse } from '../../models/reports/inspection-report.model';
import { ProductReportResponse } from '../../models/reports/product-report.model';
import { StationReportResponse } from '../../models/reports/station-report.model';
import { TrendPlagueResponse, TrendProductResponse, TrendStationResponse } from '../../models/reports/trends-report.model';
import { ReportsService } from '../../services/reports/reports.service';

@Component({
  selector: 'app-reports',
  standalone: true,
  imports: [
    LayoutComponent,
    BaseChartDirective,
    CalendarModule,
    ReactiveFormsModule,
    DropdownModule,
  ],
  templateUrl: './reports.component.html',
  styleUrl: './reports.component.scss'
})
export class ReportsComponent implements OnInit {
  private _reportsService = inject(ReportsService);
  public reportsForm: FormGroup;
  public chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          pointStyle: 'circle'
        }
      }
    }
  };

  public inspectionPlagueCategories: any | null = {};
  public inspectionPlagues: any | null = {};
  public inspectionLifeCycles: any | null = {};
  public inspectionEvidences: any | null = {};
  public inspectionDegrees: any | null = {};
  public conditionCleanings: any | null = {};
  public conditionCategories: any | null = {};
  public productApplications: any | null = {};
  public products: any | null = {};
  public productDoses: any | null = {};
  public stationsKeys: string[] = [];
  public stationActivities: any = {};
  public stationConditions: any = {};
  public stationPlagues: any = {};
  public trendPlague: any | null = null;
  public trendProduct: any | null = null;
  public trendStation: any | null = {};
  public trendStationKeys: string[] = [];
  public trendStationTitles: any = {};

  constructor(private _formBuilder: FormBuilder) {
    this.reportsForm = this._formBuilder.group({
      initialDate: [null, Validators.required],
      finalDate: [null, Validators.required],
    });
  }

  ngOnInit(): void {
    this.loadData();
    this.loadTrends();
  }

  loadData(
    initialDate?: string,
    finalDate?: string
  ): void {
    forkJoin({
      inspectionGraphic: this._reportsService.getInspectionGraphic(initialDate, finalDate),
      conditionGraphic: this._reportsService.getConditionGraphic(initialDate, finalDate),
      productGraphic: this._reportsService.getProductGraphic(initialDate, finalDate),
      stationGraphic: this._reportsService.getStationGraphic(initialDate, finalDate),
      trendStationGraphic: this._reportsService.getTrendStationGraphic(initialDate, finalDate),
    }).subscribe({
      next: (data: {
        inspectionGraphic: InspectionReportResponse,
        conditionGraphic: ConditionReportResponse,
        productGraphic: ProductReportResponse,
        stationGraphic: StationReportResponse,
        trendStationGraphic: TrendStationResponse,
      }) => {
        const inspectionPlagueCategoriesColors = data.inspectionGraphic.data.plague_categories.map(() => generateRandomColor());
        const inspectionPlaguesColors = data.inspectionGraphic.data.plagues.map(() => generateRandomColor());
        const inspectionLifeCyclesColors = data.inspectionGraphic.data.life_cycles.map(() => generateRandomColor());
        const inspectionEvidencesColors = data.inspectionGraphic.data.evidences.map(() => generateRandomColor());
        const inspectionDegreesColor = generateRandomColor()[0];

        const conditionCleaningsColors = data.conditionGraphic.data.cleanings.map(() => generateRandomColor());
        const conditionCategoriesColors = data.conditionGraphic.data.categories.map(() => generateRandomColor());

        const productApplicationsColors = data.productGraphic.data.application.map(() => generateRandomColor());
        const productsColors = data.productGraphic.data.product.map(() => generateRandomColor());
        const productDosesColors = data.productGraphic.data.dose.map(() => generateRandomColor());

        this.stationsKeys = data.stationGraphic.data.map((station) => station.StationType);

        this.inspectionPlagueCategories = !data.inspectionGraphic.data.plague_categories || data.inspectionGraphic.data.plague_categories.length === 0
          ? null
          : {
            labels: data.inspectionGraphic.data.plague_categories.map((category) => category.type + ': ' + category.count),
            datasets: [{
              label: 'Proporción de Tipos de Plaga en Inspecciones',
              data: data.inspectionGraphic.data.plague_categories.map((category) => category.count),
              backgroundColor: inspectionPlagueCategoriesColors.map(([rgbaColor]) => rgbaColor),
              borderColor: inspectionPlagueCategoriesColors.map(([, rgbColor]) => rgbColor),
              hoverOffset: 4
            }],
          };

        this.inspectionPlagues = !data.inspectionGraphic.data.plagues || data.inspectionGraphic.data.plagues.length === 0
          ? null
          : {
            labels: data.inspectionGraphic.data.plagues.map((plague) => plague.type + ': ' + plague.count),
            datasets: [{
              label: 'Proporción de Plagas en Inspecciones',
              data: data.inspectionGraphic.data.plagues.map((plague) => plague.count),
              backgroundColor: inspectionPlaguesColors.map(([rgbaColor]) => rgbaColor),
              borderColor: inspectionPlaguesColors.map(([, rgbColor]) => rgbColor),
              hoverOffset: 4
            }],
          };

        this.inspectionLifeCycles = !data.inspectionGraphic.data.life_cycles || data.inspectionGraphic.data.life_cycles.length === 0
          ? null
          : {
            labels: data.inspectionGraphic.data.life_cycles.map((lifeCycle) => lifeCycle.type + ': ' + lifeCycle.count),
            datasets: [{
              label: 'Proporción de Ciclos de Vida en Inspecciones',
              data: data.inspectionGraphic.data.life_cycles.map((lifeCycle) => lifeCycle.count),
              backgroundColor: inspectionLifeCyclesColors.map(([rgbaColor]) => rgbaColor),
              borderColor: inspectionLifeCyclesColors.map(([, rgbColor]) => rgbColor),
              hoverOffset: 4
            }],
          };

        this.inspectionEvidences = !data.inspectionGraphic.data.evidences || data.inspectionGraphic.data.evidences.length === 0
          ? null
          : {
            labels: data.inspectionGraphic.data.evidences.map((evidence) => evidence.type + ': ' + evidence.count),
            datasets: [{
              label: 'Proporción de Evidencias en Inspecciones',
              data: data.inspectionGraphic.data.evidences.map((evidence) => evidence.count),
              backgroundColor: inspectionEvidencesColors.map(([rgbaColor]) => rgbaColor),
              borderColor: inspectionEvidencesColors.map(([, rgbColor]) => rgbColor),
              hoverOffset: 4
            }],
          };

        this.inspectionDegrees = !data.inspectionGraphic.data.degree || data.inspectionGraphic.data.degree.length === 0
          ? null
          : {
            labels: data.inspectionGraphic.data.degree.map((degree) => degree.date + ': ' + degree.highest_level),
            datasets: [{
              label: 'Gráfica de Tendencia de Plaga vs Grado de Infestación',
              data: data.inspectionGraphic.data.degree.map((degree) => degree.highest_level),
              fill: false,
              borderColor: inspectionDegreesColor,
              tension: 0.1
            }],
          };

        this.conditionCleanings = !data.conditionGraphic.data.cleanings || data.conditionGraphic.data.cleanings.length === 0
          ? null
          : {
            labels: data.conditionGraphic.data.cleanings.map((cleaning) => cleaning.cleaning + ': ' + cleaning.count),
            datasets: [{
              label: 'Proporción de Evidencias en Inspecciones',
              data: data.conditionGraphic.data.cleanings.map((cleaning) => cleaning.count),
              backgroundColor: conditionCleaningsColors.map(([rgbaColor]) => rgbaColor),
              borderColor: conditionCleaningsColors.map(([, rgbColor]) => rgbColor),
              hoverOffset: 4
            }],
          };

        this.conditionCategories = !data.conditionGraphic.data.categories || data.conditionGraphic.data.categories.length === 0
          ? null
          : {
            labels: data.conditionGraphic.data.categories.map((category) => category.category + ': ' + category.count),
            datasets: [{
              label: 'Proporción de Tipo de Condiciones',
              data: data.conditionGraphic.data.categories.map((category) => category.count),
              backgroundColor: conditionCategoriesColors.map(([rgbaColor]) => rgbaColor),
              borderColor: conditionCategoriesColors.map(([, rgbColor]) => rgbColor),
              hoverOffset: 4
            }],
          };

        this.productApplications = !data.productGraphic.data.application || data.productGraphic.data.application.length === 0
          ? null
          : {
            labels: data.productGraphic.data.application.map((application) => application.method + ': ' + application.count),
            datasets: [{
              label: 'Porcentaje de Tipo de Tipo de Aplicación',
              data: data.productGraphic.data.application.map((application) => application.count),
              backgroundColor: productApplicationsColors.map(([rgbaColor]) => rgbaColor),
              borderColor: productApplicationsColors.map(([, rgbColor]) => rgbColor),
              hoverOffset: 4
            }],
          };

        this.products = !data.productGraphic.data.product || data.productGraphic.data.product.length === 0
          ? null
          : {
            labels: data.productGraphic.data.product.map((product) => product.product + ': ' + product.count),
            datasets: [{
              label: 'Porcentaje de Productos',
              data: data.productGraphic.data.product.map((product) => product.count),
              backgroundColor: productsColors.map(([rgbaColor]) => rgbaColor),
              borderColor: productsColors.map(([, rgbColor]) => rgbColor),
              hoverOffset: 4
            }],
          };

        this.productDoses = !data.productGraphic.data.dose || data.productGraphic.data.dose.length === 0
          ? null
          : {
            labels: data.productGraphic.data.dose.map((dose) => dose.dose + ': ' + dose.count),
            datasets: [{
              label: 'Porcentaje de Dosis',
              data: data.productGraphic.data.dose.map((dose) => dose.count),
              backgroundColor: productDosesColors.map(([rgbaColor]) => rgbaColor),
              borderColor: productDosesColors.map(([, rgbColor]) => rgbColor),
              hoverOffset: 4
            }],
          };

        this.stationsKeys.forEach((stationKey) => {
          const activityData = data.stationGraphic.data.find((station) => station.StationType === stationKey)!.data.activity;
          const conditionData = data.stationGraphic.data.find((station) => station.StationType === stationKey)!.data.condition;
          const plagueData = data.stationGraphic.data.find((station) => station.StationType === stationKey)!.data.plague;

          const stationActivitiesColors = activityData.map(() => generateRandomColor());
          const stationConditionsColors = conditionData.map(() => generateRandomColor());
          const stationPlaguesColors = plagueData.map(() => generateRandomColor());

          this.stationActivities[stationKey] = !activityData || activityData.length === 0
            ? null
            : {
              labels: activityData.map((activity) => activity.activity + ': ' + activity.count),
              datasets: [{
                label: 'Porcentaje de Actividad en ' + stationKey,
                data: activityData.map((activity) => activity.count),
                backgroundColor: stationActivitiesColors.map(([rgbaColor]) => rgbaColor),
                borderColor: stationActivitiesColors.map(([, rgbColor]) => rgbColor),
                hoverOffset: 4
              }],
            };

          this.stationConditions[stationKey] = !conditionData || conditionData.length === 0
            ? null
            : {
              labels: conditionData.map((condition) => condition.condition + ': ' + condition.count),
              datasets: [{
                label: 'Porcentaje de Condiciones en ' + stationKey,
                data: conditionData.map((condition) => condition.count),
                backgroundColor: stationConditionsColors.map(([rgbaColor]) => rgbaColor),
                borderColor: stationConditionsColors.map(([, rgbColor]) => rgbColor),
                hoverOffset: 4
              }],
            };

          this.stationPlagues[stationKey] = !plagueData || plagueData.length === 0
            ? null
            : {
              labels: plagueData.map((plague) => plague.plague + ': ' + plague.count),
              datasets: [{
                label: 'Plagas en ' + stationKey,
                data: plagueData.map((plague) => plague.count),
                backgroundColor: stationPlaguesColors.map(([rgbaColor]) => rgbaColor),
                borderColor: stationPlaguesColors.map(([, rgbColor]) => rgbColor),
                hoverOffset: 4
              }],
            };
        });
      },
      error: (error: any) => {
        console.error('Error al cargar los datos:', error);
      },
    })
  }

  loadTrends(): void {
    forkJoin({
      trendStationGraphic: this._reportsService.getTrendStationGraphic(),
      trendPlagueGraphic: this._reportsService.getTrendPlagueGraphic(),
      trendProductGraphic: this._reportsService.getTrendProductGraphic(),
    }).subscribe({
      next: (data: {
        trendStationGraphic: TrendStationResponse,
        trendPlagueGraphic: TrendPlagueResponse,
        trendProductGraphic: TrendProductResponse,
      }) => {
        const trendPlagueLabels = Array.from(
          new Set(
            data.trendPlagueGraphic.data.flatMap((item) => {
              const key = Object.keys(item)[0];
              return item[key].map(trend => trend.date);
            })
          )
        );

        const trendProductLabels = Array.from(
          new Set(
            data.trendProductGraphic.data.flatMap((item) => {
              const key = Object.keys(item)[0];
              return item[key].map(trend => trend.date);
            })
          )
        );

        this.trendPlague = {
          labels: trendPlagueLabels,
          datasets: data.trendPlagueGraphic.data.map((trend) => {
            const trendPlagueColors = generateRandomColor();

            const trendPlagueData = trend[Object.keys(trend)[0]].map((item: any) => item['max count']);
            const title = Object.keys(trend)[0];

            return {
              label: 'Tendencia de ' + title,
              data: trendPlagueData,
              fill: false,
              backgroundColor: trendPlagueColors[0],
              borderColor: trendPlagueColors[1],
              tension: 0.1
            };
          }),
        };

        this.trendProduct = {
          labels: trendProductLabels,
          datasets: data.trendProductGraphic.data.map((trend) => {
            const trendProductColors = generateRandomColor();

            const trendProductData = trend[Object.keys(trend)[0]].map((item: any) => item['max count']);
            const title = Object.keys(trend)[0];

            return {
              label: 'Tendencia de ' + title,
              data: trendProductData,
              fill: false,
              backgroundColor: trendProductColors[0],
              borderColor: trendProductColors[1],
              tension: 0.1
            };
          }),
        };

        const trendStationData = data.trendStationGraphic.data;

        this.trendStation = Object.keys(trendStationData).reduce((acc: any, stationKey: string) => {
          const station = trendStationData[stationKey];

          const stationLabels = Array.from(
            new Set(
              Object.values(station).flatMap((subCategory: any) =>
                subCategory.map((item: any) => item.event_date)
              )
            )
          );

          const stationDatasets = Object.keys(station).map((subCategoryKey) => {
            const subCategoryData = station[subCategoryKey];
            const stationColors = generateRandomColor();

            return {
              label: subCategoryKey,
              data: stationLabels.map(date => {
                const foundItem = subCategoryData.find((item: any) => item.event_date === date);
                return foundItem ? foundItem.count : 0;
              }),
              fill: false,
              backgroundColor: stationColors[0],
              borderColor: stationColors[1],
              tension: 0.1
            };
          });

          acc[stationKey] = {
            labels: stationLabels,
            datasets: stationDatasets
          };

          return acc;
        }, {});

        const trendStationKeys = Object.keys(this.trendStation);
        this.trendStationKeys = trendStationKeys;

        this.trendStationTitles = trendStationKeys.reduce((acc: any, stationKey: string) => {
          acc[stationKey] = 'Tendencia de ' + stationKey;
          return acc;
        }, {});
      },
      error: (error: any) => {
        console.error('Error al cargar las tendencias:', error);
      },
    });


    // const trendStationColors = generateRandomColor();

    // this.trendStation = !data.trendStationGraphic.data || data.trendStationGraphic.data.length === 0
    //   ? null
    //   : {
    //     labels: data.trendStationGraphic.data.map((trend) => trend.date + ': ' + trend.highest_level + '%'),
    //     datasets: [{
    //       label: 'Gráfica de Tendencia de Estación',
    //       data: data.trendStationGraphic.data.map((trend) => trend.highest_level),
    //       fill: false,
    //       borderColor: trendStationColors[1],
    //       tension: 0.1
    //     }],
    //   };
  }

  onSubmit(event: Event): void {
    event.preventDefault();

    if (this.reportsForm.valid) {
      const initialDate = this.reportsForm.get('initialDate')?.value;
      const formattedInitialDate = initialDate ? initialDate.toISOString().split('T')[0] : null;

      const finalDate = this.reportsForm.get('finalDate')?.value;
      const formattedFinalDate = finalDate ? finalDate.toISOString().split('T')[0] : null;

      this.loadData(formattedInitialDate, formattedFinalDate);
    } else {
      this.reportsForm.markAllAsTouched();
    }
  }
}
