import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, HostBinding, inject, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { IsActiveMatchOptions, NavigationEnd, Router, RouterLink, RouterModule } from '@angular/router';
import { TooltipModule } from 'primeng/tooltip';
import { filter, Subscription } from 'rxjs';

import { LayoutService } from '../../../services/layout/layout.service';
import { MenuService } from '../../../services/layout/menu.service';
import { SidebarComponent } from '../sidebar/sidebar.component';

@Component({
  selector: 'app-menu-item',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    RouterLink,
    TooltipModule
  ],
  animations: [
    trigger('children', [
      state('collapsed', style({
        height: '0'
      })),
      state('expanded', style({
        height: '*'
      })),
      state('hidden', style({
        display: 'none'
      })),
      state('visible', style({
        display: 'block'
      })),
      transition('collapsed <=> expanded', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
    ])
  ],
  templateUrl: './menu-item.component.html',
  styleUrl: './menu-item.component.scss'
})
export class MenuItemComponent implements OnInit, OnDestroy {
  @Input() item: any;
  @Input() index!: number;
  @Input() @HostBinding('class.layout-root-menuitem') root!: boolean;
  @Input() parentKey!: string;
  @ViewChild('submenu') submenu!: ElementRef;

  private _layoutService = inject(LayoutService);
  private _router = inject(Router);
  private _menuService = inject(MenuService);

  public active = false;
  public menuSourceSubscription: Subscription;
  public menuResetSubscription: Subscription;
  public key: string = "";

  constructor(private cd: ChangeDetectorRef, private appSidebar: SidebarComponent) {
    this.menuSourceSubscription = this._menuService.menuSource$.subscribe(value => {
      Promise.resolve(null).then(() => {
        if (value.routeEvent) {
          this.active = (value.key === this.key || value.key.startsWith(this.key + '-')) ? true : false;
        }
        else {
          if (value.key !== this.key && !value.key.startsWith(this.key + '-')) {
            this.active = false;
          }
        }
      });
    });

    this.menuResetSubscription = this._menuService.resetSource$.subscribe(() => {
      this.active = false;
    });

    this._router.events.pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((params) => {
        if (this.isSlimPlus || this.isSlim) {
          this.active = false;
        } else {
          if (this.item.routerLink) {
            this.updateActiveStateFromRoute();
          }
        }
      });
  }

  ngOnInit() {
    this.key = this.parentKey ? this.parentKey + '-' + this.index : String(this.index);

    if (!this.isSlim && this.item.routerLink) {
      this.updateActiveStateFromRoute();
    }
  }

  ngAfterViewChecked() {
    if (this.root && this.active && this._layoutService.isDesktop() && (this._layoutService.isSlim() || this._layoutService.isSlimPlus())) {
      this.calculatePosition(this.submenu?.nativeElement, this.submenu?.nativeElement.parentElement);
    }
  }

  onSubmenuAnimated(event: any) {
    if ((event as any).toState === 'visible' && this._layoutService.isDesktop() && (this._layoutService.isSlim() || this._layoutService.isSlimPlus())) {
      const el = <HTMLUListElement>event.element;
      const elParent = <HTMLUListElement>el.parentElement;
      this.calculatePosition(el, elParent);
    }
  }

  calculatePosition(overlay: HTMLElement, target: HTMLElement) {
    if (overlay) {
      const { left, top } = target.getBoundingClientRect();

      const vHeight = window.innerHeight;
      const oHeight = overlay.offsetHeight;
      const topbarEl = document.querySelector('.layout-topbar') as HTMLElement;
      const topbarHeight = topbarEl?.offsetHeight || 0;

      overlay.style.top = '';
      overlay.style.left = '';

      if (this._layoutService.isSlim() || this._layoutService.isSlimPlus()) {
        const topOffset = top - topbarHeight;
        const height = topOffset + oHeight + topbarHeight;
        overlay.style.top = vHeight < height ? `${topOffset - (height - vHeight)}px` : `${topOffset}px`;
      }
    }
  }

  updateActiveStateFromRoute() {
    let activeRoute = this._router.isActive(this.item.routerLink[0], (<IsActiveMatchOptions>this.item.routerLinkActiveOptions || { paths: 'exact', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' }));

    if (activeRoute) {
      this._menuService.onMenuStateChange({ key: this.key, routeEvent: true });
    }
  }

  itemClick(event: MouseEvent) {
    if (this.item.disabled) {
      event.preventDefault();
      return;
    }

    if (this.root && this.isSlim || this.isSlimPlus) {
      this._layoutService.state.menuHoverActive = !this._layoutService.state.menuHoverActive;
    }

    if (this.item.command) {
      this.item.command({ originalEvent: event, item: this.item });
    }

    if (event.metaKey && this.item.routerLink && (!this.item.data || !this.item.data.fullPage)) {
      this._layoutService.onTabOpen(this.item);
      event.preventDefault();
    }

    if (this.item.items) {
      this.active = !this.active;

      if (this.root && this.active && (this.isSlim || this.isSlimPlus)) {
        this._layoutService.onOverlaySubmenuOpen();
      }
    } else {
      if (this._layoutService.isMobile()) {
        this._layoutService.state.staticMenuMobileActive = false;
      }

      if (this.isSlim || this.isSlimPlus) {
        this._menuService.reset();
        this._layoutService.state.menuHoverActive = false;
      }
    }

    this._menuService.onMenuStateChange({ key: this.key });
  }

  onMouseEnter() {
    if (this.root && (this.isSlim || this.isSlimPlus) && this._layoutService.isDesktop()) {
      if (this._layoutService.state.menuHoverActive) {
        this.active = true;
        this._menuService.onMenuStateChange({ key: this.key });
      }
    }
  }

  get submenuAnimation() {
    if (this._layoutService.isDesktop() && (this._layoutService.isSlim() || this._layoutService.isSlimPlus())) {
      return this.active ? 'visible' : 'hidden';
    } else {
      return this.root ? 'expanded' : (this.active ? 'expanded' : 'collapsed');
    }
  }

  get isSlim() {
    return this._layoutService.isSlim();
  }

  get isSlimPlus() {
    return this._layoutService.isSlimPlus();
  }

  get isMobile() {
    return this._layoutService.isMobile();
  }

  @HostBinding('class.active-menuitem')
  get activeClass() {
    return this.active && !this.root;
  }

  ngOnDestroy() {
    if (this.menuSourceSubscription) {
      this.menuSourceSubscription.unsubscribe();
    }

    if (this.menuResetSubscription) {
      this.menuResetSubscription.unsubscribe();
    }
  }
}
