<div class="layout-topbar">
  <a class="" routerLink="/">
    <h2 class="text-white fw-bold">
      PestwareApp
    </h2>
  </a>

  <button #menubutton class="topbar-menubutton p-link" type="button" (click)="onMenuButtonClick()">
    <span></span>
  </button>

  <ul class="topbar-menu">
    <li *ngFor="let item of tabs; let i = index">
      <a [routerLink]="item.routerLink" routerLinkActive="active-route"
        [routerLinkActiveOptions]="item.routerLinkActiveOptions||{paths: 'exact', queryParams: 'ignored', fragment: 'ignored', matrixParams: 'ignored'}"
        [fragment]="item.fragment" [queryParamsHandling]="item.queryParamsHandling"
        [preserveFragment]="item.preserveFragment!" [skipLocationChange]="item.skipLocationChange!"
        [replaceUrl]="item.replaceUrl!" [state]="item.state" [queryParams]="item.queryParams">
        <span>{{item.label}}</span>
      </a>

      <i class="pi pi-times" (click)="removeTab($event, item, i)"></i>
    </li>
  </ul>

  <div class="topbar-profile">
    <button class="topbar-profile-button p-link" (click)="menu.toggle($event)">
      <img alt="avatar" src="/img/avatar.png">

      <span class="profile-details">
        <span class="profile-name">
          @if (profile) {
            {{ profile.user.first_name }}
          }
        </span>

        <span class="profile-job">
          @if (profile) {
            {{ profile.user.last_name }}
          }
        </span>
      </span>

      <i class="pi pi-angle-down"></i>
    </button>

    <p-menu #menu [model]="menuItems" [popup]="true"></p-menu>
  </div>
</div>
