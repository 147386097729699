<div class="layout-container" [ngClass]="containerClass">
  <app-topbar></app-topbar>
  <app-sidebar></app-sidebar>

  <div class="layout-content-wrapper">
    <div class="layout-content">
      <div class="mx-5">
        <ng-content></ng-content>
      </div>
    </div>
  </div>
</div>