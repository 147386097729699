import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { IconFieldModule } from 'primeng/iconfield';
import { InputIconModule } from 'primeng/inputicon';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { TableModule } from 'primeng/table';
import { TagModule } from 'primeng/tag';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { FormBuilder, FormGroup } from '@angular/forms';

import { environment } from '../../../../environments/environment.prod';
import { STATUS_COLORS } from '../../../shared/constants/colors';
import type { Event as EventType, Service } from '../../models/events.model';
import { DocumentsService } from '../../services/documents/documents.service';
import { EventsService } from '../../services/events/events.service';
import { PaginatorModule } from 'primeng/paginator';

@Component({
  selector: 'app-services-table',
  standalone: true,
  imports: [
    ButtonModule,
    DropdownModule,
    IconFieldModule,
    InputIconModule,
    InputTextModule,
    MultiSelectModule,
    TableModule,
    TagModule,
    ToastModule,
    TooltipModule,
    CommonModule,
    PaginatorModule
  ],
  providers: [MessageService],
  templateUrl: './services-table.component.html',
  styleUrl: './services-table.component.scss',
})
export class ServicesTableComponent implements OnInit {
  private _eventsService = inject(EventsService);
  private _documentsService = inject(DocumentsService);
  private _router = inject(Router);

  public services: Service[] = [];
  public statuses!: any[];
  public loading: boolean = false;
  public loadingServiceOrder: { [key: string]: boolean } = {};
  public loadingServiceCertificate: { [key: string]: boolean } = {};

  public totalRecords: number = 0;
  public currentPage: number = 1;
  public rows: number = 10;
  public firstPage: number = 0;

  constructor(
    private _messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.loadServices(this.currentPage, this.rows);

    this.statuses = Object.keys(STATUS_COLORS).map((status) => ({
      label: status,
      value: status,
    }));
  }

  filterServices(event: Event) {
    const inputElement = event.target as HTMLInputElement;
    console.log(inputElement.value);
    // this.dt2.filterGlobal(inputElement.value, 'contains');
  }

  loadServices(page: number, pageSize: number) {
    this.loading = true;

    this._eventsService.getEvents(page, pageSize).subscribe({
      next: (data: {
        items: EventType[];
        total: number;
        page: number;
        page_size: number;
      }) => {
        this.services = data.items.map((service) => ({
          ...service,
          employee: service.employee[0],
        }));

        this.totalRecords = data.total;
        this.currentPage = data.page;

        this.loading = false;
      },
      error: (err) => {
        console.error('Error al cargar servicios:', err);
        this.loading = false;
      },
    });
  }

  handleSeeDetails(service: Service) {
    this._router.navigate(['/event-details', service.id]);
  }

  handleServiceOrderDocument(service: Service) {
    this.loadingServiceOrder[service.id] = true;

    this._documentsService.getServiceOrderPdf(service.id).subscribe({
      next: (response) => {
        const url = environment.documentStorageUrl + response.data;
        window.open(url, '_blank');
      },
      error: (err) => {
        console.error('Error fetching service order: ', err);
        this._messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener la orden de servicio' });

        this.loadingServiceOrder[service.id] = false;
      },
      complete: () => {
        this.loadingServiceOrder[service.id] = false;
      }
    });
  }

  handleServiceOrderCertificate(service: Service) {
    this.loadingServiceCertificate[service.id] = true;

    this._documentsService.getServiceCertificatePdf(service.id).subscribe({
      next: (response) => {
        const url = environment.documentStorageUrl + response.data;
        window.open(url, '_blank');
      },
      error: (err) => {
        console.error('Error fetching service certificate: ', err);
        this._messageService.add({ severity: 'error', summary: 'Error', detail: 'Error al obtener el certificado del servicio' });

        this.loadingServiceCertificate[service.id] = false;
      },
      complete: () => {
        this.loadingServiceCertificate[service.id] = false;
      }
    });
  }

  getSeverity(status: string) {
    switch (status) {
      case 'Cancelado': return 'danger';
      case 'Finalizado': return 'success';
      case 'Completado': return 'success';
      case 'Creado': return 'info';
      case 'Confirmado': return 'info';
      case 'Pendiente': return 'warning';
      case 'Programado': return 'info';
      case 'Comenzado': return 'warning';
      case 'En progreso': return 'warning';
      case 'Sincronizado': return undefined;
      default:
        return undefined;
    }
  }

  formatTime(time: string): string {
    const timeParts = time.split(':');
    return `${timeParts[0]}:${timeParts[1]}`;
  }

  pageChange(event: any) {
    const newPage = event.first / event.rows;
    this.firstPage = event.first;

    this.loadServices(newPage, event.rows);
  }
}
