<p-toast />

<div class="containerr mb-3 body">
  <div class="forms-container">
    <div class="sign-in-signup">
      <form
        [formGroup]="loginForm"
        novalidate
        (submit)="onSubmit($event)"
      >
        <img
          [src]="logoPestware"
          class="image logo-image"
          alt="logo"
          height="200px"
          style="margin-bottom: 50px;"
        >

        <h2 class="title">Inicio sesión</h2>

        <div fxFlex="row" fxLayoutGap="10" class="p-fluid grid formgrid mb-4" style="width: 80% !important;">
          <span class="p-input-icon-left p-float-label">
            <i class="pi pi-user"></i>
            <input formControlName="email" pInputText type="email" class="p-inputtext-lg">
            <label htmlFor="email">Usuario</label>
          </span>

          @if (hasErrors('email', 'required')) {
            <span class="error">
              El usuario es requerido
            </span>
          }

          @if (hasErrors('email', 'email')) {
            <span class="error">
              El usuario debe ser un correo electrónico válido
            </span>
          }
        </div>

        <div fxFlex="row" class="p-fluid grid formgrid mb-4" style="width: 80% !important;">
          <span class="p-float-label p-input-icon-left">
            <i class="pi pi-key"></i>

            <p-password formControlName="password" [toggleMask]="true" class="p-inputtext-lg" styleClass="p-password p-component p-inputwrapper p-input-icon-right">
              <ng-template pTemplate="header">
                <h6>Ingresar Contraseña</h6>
              </ng-template>
            </p-password>

            <label>Contraseña</label>
          </span>

          @if (hasErrors('password', 'required')) {
            <span class="error">
              La contraseña es requerida
            </span>
          }
        </div>

        <br>

        <p-button [disabled]="loginForm.invalid" label="Ingresar ahora" type="submit" styleClass=" p-button mb-3 boton-largo">
        </p-button>
      </form>
    </div>
  </div>

  <div class="panels-containerr">
    <div class="panel left-panel">
      <div class="content">
        <h2 class="left-panel-title">
          Bienvenido al Portal de Clientes
        </h2>

        <span class="left-panel-subtitle">
          Inicia sesión para acceder a la plataforma
        </span>
      </div>

      <img src="https://pestwareapp.com/loginew/img/log.svg" class="image" alt="">
    </div>
  </div>
</div>
