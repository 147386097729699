import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';
import { DropdownModule } from 'primeng/dropdown';
import { TableModule } from 'primeng/table';
import { DialogModule } from 'primeng/dialog';
import { forkJoin, map, Observable, switchMap } from 'rxjs';

import { LayoutComponent } from '../../../shared/layouts/layout/layout.component';
import { AreasResponse } from '../../models/areas.model';
import { AreasService } from '../../services/areas/areas.service';

interface Area {
  id: string;
  station: string;
  station_activity: string;
  station_type_activity: string;
  station_condition: string;
  plagues: {
    plague: string;
    count: number;
  }[];
  activity_in_station: string;
  employee_activity: string;
  photos: string[];
}

interface Service {
  id: string;
  folio: string;
  event: string;
  date: string;
  hour: string;
  employee: string;
  customer_name: string;
  customer_address: string;
  no_of_areas: number;
  areas: Area[];
}

@Component({
  selector: 'app-areas',
  standalone: true,
  imports: [
    CommonModule,
    DialogModule,
    DropdownModule,
    LayoutComponent,
    TableModule,
  ],
  templateUrl: './areas.component.html',
  styleUrl: './areas.component.scss'
})
export class AreasComponent implements OnInit {
  private _areasService = inject(AreasService);

  public loadingServices = false;
  public loadingAreas = false;
  public isModalVisible = false;

  public activeService: Service | null = null;
  public services: Service[] = [];
  public areas: Area[] = [];

  public totalRecords: number = 0;
  public currentPage: number = 1;
  public rows: number = 10;

  ngOnInit(): void {
    this.loadAreas(this.currentPage, this.rows);
  }

  loadAreas(page: number, pageSize: number): void {
    this.loadingServices = true;

    this._areasService.getAreas().pipe(
      switchMap((data: AreasResponse[]) => {
        const uniqueEvents = data.reduce((acc: any[], item: any) => {
          if (!acc.find((event: any) => event.event === item.event)) {
            acc.push(item);
          }

          return acc;
        }, []);

        const requests = uniqueEvents.map((item) => {
          const areas = data.filter((area) => area.event === item.event);

          return forkJoin({
            eventData: this._areasService.getEventData(item.event),
          }).pipe(
            map((result) => ({
              ...item,
              ...result,
              areas,
            }))
          )
        });

        return forkJoin(requests);
      })
    ).subscribe({
      next: (results: any[]) => {
        this.services = results.flatMap((result) => {
          return {
            id: result.eventData.data.id,
            folio: result.eventData.data.folio,
            event: result.eventData.data.title,
            date: result.eventData.data.initial_date,
            hour: result.eventData.data.initial_hour,
            employee: result.eventData.data.employee.map((employee: any) => employee.name).join(', '),
            customer_name: result.eventData.data.customer.name,
            customer_address: result.eventData.data.customer.address,
            no_of_areas: result.areas.length,
            areas: result.areas.map((area: any) => ({
              id: area.id,
              station: area.station.name,
              station_activity: area.station_activity.map((activity: any) => activity.name).join(', '),
              station_type_activity: area.stationTypeActivity.map((activity: any) => activity.name).join(', '),
              station_condition: area.station_condition.map((condition: any) => condition.name).join(', '),
              plagues: area.plague_counts.map((plague: any) => ({
                plague: plague.plague.name,
                count: plague.count_plague,
              })),
              activity_in_station: area.activityinStation,
              employee_activity: area.EmployeeActivity,
              photos: []
            })),
          };
        });

        this.loadingServices = false;
      },
      error: (err) => {
        console.error('Error al cargar los datos:', err);
        this.loadingServices = false;
      }
    });
  }

  handleOpenModal(service: Service): void {
    this.loadingAreas = true;
    this.activeService = service;
    this.areas = service.areas;

    const photosRequest = service.areas.map((area) => {
      if (area.id) {
        return this._areasService.getAreasPhotos(area.id).pipe(
          map((response) => ({
            ...area,
            photos: response.map((photo) => photo.photo),
          }))
        );
      }

      return new Observable((observer) => {
        observer.next(area);
        observer.complete();
      });
    });

    forkJoin(photosRequest).subscribe({
      next: (updatedAreas: any) => {
        this.areas = updatedAreas;
        this.isModalVisible = true;
        this.loadingAreas = false;
      },
      error: (err) => {
        console.error('Error al obtener las fotos:', err);
        this.loadingAreas = false;
      },
    });
  }
}
