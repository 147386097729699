import { CommonModule, NgClass } from '@angular/common';
import { Component, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-change-password',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgClass,
  ],
  templateUrl: './change-password.component.html',
  styleUrl: './change-password.component.scss'
})
export class ChangePasswordComponent {
  private _router = inject(Router);
  public changePasswordForm: FormGroup;

  constructor(private _formBuilder: FormBuilder) {
    this.changePasswordForm = this._formBuilder.group({
      currentPassword: ['', [Validators.required]],
      newPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]]
    }, { validator: this.passwordsMatchValidator });
  }

  passwordsMatchValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const newPassword = control.get('newPassword')?.value;
    const confirmPassword = control.get('confirmPassword')?.value;

    if (newPassword !== confirmPassword) {
      return { passwordsMismatch: true };
    }

    return null;
  }

  public onSubmit(event: Event): void {
    event.preventDefault();

    if (this.changePasswordForm.valid) {
      //
    } else {
      this.changePasswordForm.markAllAsTouched();
    }
  }

  public hasErrors(field: string, typeError: string) {
    return this.changePasswordForm.get(field)?.hasError(typeError) && this.changePasswordForm.get(field)?.touched;
  }

  public hasPasswordMismatch() {
    return this.changePasswordForm.hasError('passwordsMismatch') && this.changePasswordForm.touched;
  }

  public goBack() {
    this._router.navigate(['/']);
  }
}
