<app-layout>
  <header>
    <h2>
      <i class="pi pi-chart-bar"></i> Reportes y estadísticas
    </h2>

    <form
      [formGroup]="reportsForm"
      novalidate
      (submit)="onSubmit($event)"
      class="form"
    >
      <figcaption class="form-figcaption">
        Puedes filtrar los reportes por fecha
      </figcaption>

      <div class="form-group">
        <p-calendar
          formControlName="initialDate"
          [iconDisplay]="'input'"
          [showIcon]="true"
          inputId="icondisplay"
          placeholder="Fecha de inicio"
        />

        <p-calendar
          formControlName="finalDate"
          [iconDisplay]="'input'"
          [showIcon]="true"
          inputId="icondisplay"
          placeholder="Fecha de final"
        />

        <button
          type="submit"
          class="button"
        >
          Filtrar reportes
        </button>
      </div>
    </form>
  </header>

  <section class="graphics-section">
    @if (!(
      inspectionPlagueCategories == null &&
      inspectionPlagues == null &&
      inspectionLifeCycles == null &&
      inspectionEvidences == null
    )) {
      <article class="graphic-item">
        <h3 class="graphic-section-title">
          Inspecciones
        </h3>

        <div class="graphic-grid">
          @if (inspectionPlagueCategories) {
            <div>
              <h4 class="graphic-title">
                Tipos de plaga en inspecciones
              </h4>

              <div>
                <canvas
                  baseChart
                  [data]="inspectionPlagueCategories"
                  [options]="chartOptions"
                  type="pie"
                >
                </canvas>
              </div>
            </div>
          }

          @if (inspectionPlagues) {
            <div>
              <h4 class="graphic-title">
                Plagas en inspecciones
              </h4>

              <div>
                <canvas
                  baseChart
                  [data]="inspectionPlagues"
                  [options]="chartOptions"
                  type="pie"
                >
                </canvas>
              </div>
            </div>
          }

          @if (inspectionLifeCycles) {
            <div>
              <h4 class="graphic-title">
                Ciclos de vida en inspecciones
              </h4>

              <div>
                <canvas
                  baseChart
                  [data]="inspectionLifeCycles"
                  [options]="chartOptions"
                  type="pie"
                >
                </canvas>
              </div>
            </div>
          }

          @if (inspectionEvidences) {
            <div>
              <h4 class="graphic-title">
                Evidencias en inspecciones
              </h4>

              <div>
                <canvas
                  baseChart
                  [data]="inspectionEvidences"
                  [options]="chartOptions"
                  type="pie"
                >
                </canvas>
              </div>
            </div>
          }
        </div>
      </article>
    }

    @if (!(
      conditionCategories == null &&
      conditionCleanings == null
    )) {
      <article class="graphic-item">
        <h3 class="graphic-section-title">
          Condiciones
        </h3>

        <div class="graphic-grid">
          @if (conditionCategories) {
            <div>
              <h4 class="graphic-title">
                Tipo de condiciones
              </h4>

              <div>
                <canvas
                  baseChart
                  [data]="conditionCategories"
                  [options]="chartOptions"
                  type="pie"
                >
                </canvas>
              </div>
            </div>
          }

          @if (conditionCleanings) {
            <div>
              <h4 class="graphic-title">
                Evidencias en inspecciones
              </h4>

              <div>
                <canvas
                  baseChart
                  [data]="conditionCleanings"
                  [options]="chartOptions"
                  type="pie"
                >
                </canvas>
              </div>
            </div>
          }
        </div>
      </article>
    }

    @if (stationsKeys && stationsKeys.length > 0) {
      <article class="graphic-item">
        <h3 class="graphic-section-title">
          Monitoreo de estaciones
        </h3>

        @for (stationKey of stationsKeys; track $index) {
          <div class="station-container">
            <h4>
              Proporciones en {{ stationKey }}
            </h4>

            <div class="graphic-grid">
              @if (stationActivities[stationKey]) {
                <div>
                  <h4 class="graphic-title">
                    Actividad en {{ stationKey }}
                  </h4>

                  <div>
                    <canvas
                      baseChart
                      [data]="stationActivities[stationKey]"
                      [options]="chartOptions"
                      type="pie"
                    >
                    </canvas>
                  </div>
                </div>
              }

              @if (stationConditions[stationKey]) {
                <div>
                  <h4 class="graphic-title">
                    Condiciones en {{ stationKey }}
                  </h4>

                  <div>
                    <canvas
                      baseChart
                      [data]="stationConditions[stationKey]"
                      [options]="chartOptions"
                      type="pie"
                    >
                    </canvas>
                  </div>
                </div>
              }

              @if (stationPlagues[stationKey]) {
                <div>
                  <h4 class="graphic-title">
                    Plagas en {{ stationKey }}
                  </h4>

                  <div>
                    <canvas
                      baseChart
                      [data]="stationPlagues[stationKey]"
                      [options]="chartOptions"
                      type="pie"
                    >
                    </canvas>
                  </div>
                </div>
              }
            </div>
          </div>
        }
      </article>
    }

    @if (!(
      productApplications == null &&
      products == null &&
      productDoses == null
    )) {
      <article class="graphic-item">
        <h3 class="graphic-section-title">
          Productos
        </h3>

        <div class="graphic-grid">
          @if (productApplications) {
            <div>
              <h4 class="graphic-title">
                Tipo de aplicación
              </h4>

              <div>
                <canvas
                  baseChart
                  [data]="productApplications"
                  [options]="chartOptions"
                  type="pie"
                >
                </canvas>
              </div>
            </div>
          }

          @if (products) {
            <div>
              <h4 class="graphic-title">
                Productos
              </h4>

              <div>
                <canvas
                  baseChart
                  [data]="products"
                  [options]="chartOptions"
                  type="pie"
                >
                </canvas>
              </div>
            </div>
          }

          @if (productDoses) {
            <div>
              <h4 class="graphic-title">
                Dosis
              </h4>

              <div>
                <canvas
                  baseChart
                  [data]="productDoses"
                  [options]="chartOptions"
                  type="pie"
                >
                </canvas>
              </div>
            </div>
          }
        </div>
      </article>
    }

    <article class="graphic-item">
      <h3 class="graphic-section-title">
        Tendencia de plagas
      </h3>

      @if (trendPlague) {
        <div class="graphic-trend">
          <canvas
            baseChart
            [data]="trendPlague"
            [options]="chartOptions"
            type="line"
          >
          </canvas>
        </div>
      }
    </article>

    <article class="graphic-item">
      <h3 class="graphic-section-title">
        Tendencia de productos
      </h3>

      @if (trendProduct) {
        <div class="graphic-trend">
          <canvas
            baseChart
            [data]="trendProduct"
            [options]="chartOptions"
            type="line"
          >
          </canvas>
        </div>
      }
    </article>

    @if (trendStation) {
      <article class="graphic-item">
        <h3 class="graphic-section-title">
          Tendencia de estaciones
        </h3>

        @if (trendStationKeys && trendStationKeys.length > 0) {
          @for (key of trendStationKeys; track $index) {
            <div class="graphic-trend">
              <h4 class="graphic-title">
                {{ trendStationTitles[key] }}
              </h4>

              <canvas baseChart [data]="trendStation[key]" type="line">
              </canvas>
            </div>
          }
        }
      </article>
    }

    @if (inspectionDegrees) {
      <article class="graphic-item">
        <h3 class="graphic-section-title">
          Tendencia de inspecciones
        </h3>

        <div class="graphic-trend">
          <h4 class="graphic-title">
            Tendencia de plaga vs grado de infestación
          </h4>

          <canvas
            baseChart
            [data]="inspectionDegrees"
            [options]="chartOptions"
            type="line"
          >
          </canvas>
        </div>
      </article>
    }
  </section>
</app-layout>
