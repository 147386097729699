import { CommonModule } from '@angular/common';
import { Component, inject, OnInit } from '@angular/core';

import { LayoutService } from '../../../services/layout/layout.service';
import { MenuItemComponent } from '../menu-item/menu-item.component';

@Component({
  selector: 'app-menu',
  standalone: true,
  imports: [
    MenuItemComponent,
    CommonModule
  ],
  templateUrl: './menu.component.html',
  styleUrl: './menu.component.scss'
})
export class MenuComponent implements OnInit {
  private _layoutService = inject(LayoutService);

  public model: any[] = [];

  ngOnInit() {
    const itemsMenu = [
      {
        label: 'Clientes', icon: 'pi pi-user',
        items: [
          {
            label: 'Inicio',
            permissionKey: 'start',
            icon: 'pi pi-home',
            routerLink: ['/']
          },
          {
            label: 'Servicios programados',
            permissionKey: 'start',
            icon: 'pi pi-calendar',
            routerLink: ['/services']
          },
          {
            label: 'Reportes de servicios',
            permissionKey: 'start',
            icon: 'pi pi-chart-bar',
            routerLink: ['/reports']
          },
          {
            label: 'Tickets',
            permissionKey: 'start',
            icon: 'pi pi-ticket',
            routerLink: ['/tracings']
          },
          {
            label: 'Carpeta MIP',
            permissionKey: 'start',
            icon: 'pi pi-folder-open',
            routerLink: ['/mip-folders']
          },
          {
            label: 'Inspecciones',
            permissionKey: 'start',
            icon: 'pi pi-lightbulb',
            routerLink: ['/inspections']
          },
          {
            label: 'Áreas de inspecciones',
            permissionKey: 'start',
            icon: 'pi pi-map',
            routerLink: ['/areas']
          }
        ]
      },
      // {
      //   label: 'Administrativo', permissionKey: 'job_centers', icon: 'pi pi-briefcase',
      //   items: [
      //     {
      //       label: 'Recursos humanos', permissionKey: 'job_centers', icon: 'fa-solid fa-users-rays',
      //       items: [
      //         {
      //           label: 'Empleados', permissionKey: 'job_centers', icon: 'fa-solid fa-users',
      //           routerLink: ['/administrative/employee']
      //         },
      //         {
      //           label: 'Centros de trabajo', permissionKey: 'job_centers', icon: 'fa-solid fa-building-user',
      //           routerLink: ['/administrative/job_center']
      //         }
      //       ]
      //     },
      //     {
      //       label: 'Inventario', permissionKey: 'inventories', icon: 'fa-solid fa-warehouse',
      //       items: [
      //         {
      //           label: 'Proveedores', permissionKey: 'inventories', icon: 'fa-solid fa-building-user',
      //           routerLink: ['/inventory/providers']
      //         },
      //         {
      //           label: 'Productos', permissionKey: 'inventories', icon: 'fa-solid fa-flask',
      //           routerLink: ['/inventory/products']
      //         }
      //       ]
      //     },
      //     {
      //       label: 'Gastos',
      //       permissionKey: 'expenses',
      //       icon: 'fa fa-money',
      //       routerLink: ['/administrative/expenses']
      //     },
      //   ]
      // }
    ];

    // this.model = this.filterMenuByPermissions(itemsMenu, this.permissionService.Permissions);
    this.model = itemsMenu;
  }

  // filterMenuByPermissions(menu: any[], permissions: any[]): any[] {
  //   const permissionMap = new Map(permissions.map((p) => [p.label, p.permissions.view]));

  //   const filterItems: any = (items: any[]) => {
  //     return items
  //       .filter((item) => !item.permissionKey || permissionMap.get(item.permissionKey))
  //       .map((item) => ({
  //         ...item,
  //         items: item.items ? filterItems(item.items) : undefined
  //       }))
  //       .filter((item) => item.items ? item.items.length > 0 || !item.items : true);
  //   };

  //   return filterItems(menu);
  // }
}
