import { CommonModule } from '@angular/common';
import { Component, inject, OnDestroy, OnInit, Renderer2, ViewChild } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { filter, Subscription } from 'rxjs';

import { SidebarComponent } from '../../components/layout-components/sidebar/sidebar.component';
import { TopbarComponent } from '../../components/layout-components/topbar/topbar.component';
import { TabCloseEvent } from '../../models/tab-event.model';
import { LayoutService } from '../../services/layout/layout.service';
import { MenuService } from '../../services/layout/menu.service';

@Component({
  selector: 'app-layout',
  standalone: true,
  imports: [
    CommonModule,
    TopbarComponent,
    SidebarComponent,
  ],
  templateUrl: './layout.component.html',
  styleUrl: './layout.component.scss'
})
export class LayoutComponent implements OnInit, OnDestroy {
  private _menuService = inject(MenuService);
  private _layoutService = inject(LayoutService);
  private _router = inject(Router);

  public overlayMenuOpenSubscription: Subscription;
  public tabOpenSubscription: Subscription;
  public tabCloseSubscription: Subscription;
  public menuOutsideClickListener: any;
  public menuScrollListener: any;

  @ViewChild(SidebarComponent) appSidebar!: SidebarComponent;
  @ViewChild(TopbarComponent) appTopbar!: TopbarComponent;

  constructor(public renderer: Renderer2) {
    this.overlayMenuOpenSubscription = this._layoutService.overlayOpen$.subscribe(() => {
      if (!this.menuOutsideClickListener) {
        this.menuOutsideClickListener = this.renderer.listen('document', 'click', event => {
          const isOutsideClicked = !(
            this.appSidebar.el.nativeElement.isSameNode(event.target) ||
            this.appSidebar.el.nativeElement.contains(event.target) ||
            this.appTopbar.menuButton.nativeElement.isSameNode(event.target) ||
            this.appTopbar.menuButton.nativeElement.contains(event.target)
          );

          if (isOutsideClicked) {
            this.hideMenu();
          }
        });
      }

      if ((this._layoutService.isSlim() || this._layoutService.isSlimPlus()) && !this.menuScrollListener) {
        this.menuScrollListener = this.renderer.listen(this.appSidebar.menuContainer.nativeElement, 'scroll', event => {
          if (this._layoutService.isDesktop()) {
            this.hideMenu();
          }
        });
      }

      if (this._layoutService.state.staticMenuMobileActive) {
        this.blockBodyScroll();
      }
    });

    this._router.events.pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this.hideMenu();
      });

    this.tabOpenSubscription = this._layoutService.tabOpen$.subscribe((tab) => {
      this._router.navigate(tab.routerLink);
      this._layoutService.openTab(tab);
    });

    this.tabCloseSubscription = this._layoutService.tabClose$.subscribe((event: TabCloseEvent) => {
      if (this._router.isActive(event.tab.routerLink[0], { paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored' })) {
        const tabs = this._layoutService.tabs;

        if (tabs.length > 1) {
          if (event.index === (tabs.length - 1)) {
            this._router.navigate(tabs[tabs.length - 2].routerLink);
          } else {
            this._router.navigate(tabs[event.index + 1].routerLink);
          }
        } else {
          this._router.navigate(['/']);
        }
      }

      this._layoutService.closeTab(event.index);
    });
  }

  ngOnInit(): void { }

  ngOnDestroy() {
    if (this.overlayMenuOpenSubscription) {
      this.overlayMenuOpenSubscription.unsubscribe();
    }

    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
    }

    if (this.tabOpenSubscription) {
      this.tabOpenSubscription.unsubscribe();
    }

    if (this.tabCloseSubscription) {
      this.tabCloseSubscription.unsubscribe();
    }
  }

  blockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll');
    } else {
      document.body.className += ' blocked-scroll';
    }
  }

  unblockBodyScroll(): void {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll');
    } else {
      document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ');
    }
  }

  hideMenu() {
    this._layoutService.state.overlayMenuActive = false;
    this._layoutService.state.staticMenuMobileActive = false;
    this._layoutService.state.menuHoverActive = false;
    this._menuService.reset();

    if (this.menuOutsideClickListener) {
      this.menuOutsideClickListener();
      this.menuOutsideClickListener = null;
    }

    if (this.menuScrollListener) {
      this.menuScrollListener();
      this.menuScrollListener = null;
    }

    this.unblockBodyScroll();
  }

  get containerClass() {
    return {
      'layout-slim': this._layoutService.config.menuMode === 'slim',
      'layout-slim-plus': this._layoutService.config.menuMode === 'slim-plus',
      'layout-static': this._layoutService.config.menuMode === 'static',
      'layout-overlay': this._layoutService.config.menuMode === 'overlay',
      'layout-overlay-active': this._layoutService.state.overlayMenuActive,
      'layout-mobile-active': this._layoutService.state.staticMenuMobileActive,
      'layout-static-inactive': this._layoutService.state.staticMenuDesktopInactive && this._layoutService.config.menuMode === 'static',
      'p-input-filled': this._layoutService.config.inputStyle === 'filled',
      'p-ripple-disabled': !this._layoutService.config.ripple,
      'layout-light': this._layoutService.config.layoutTheme === 'colorScheme' && this._layoutService.config.colorScheme === 'light',
      'layout-dark': this._layoutService.config.layoutTheme === 'colorScheme' && this._layoutService.config.colorScheme === 'dark',
      'layout-primary': this._layoutService.config.colorScheme !== 'dark' && this._layoutService.config.layoutTheme === 'primaryColor'
    }
  }

  changeTheme(theme: string) {
    const themeLink = <HTMLLinkElement>document.getElementById('theme-link');
    const newHref = themeLink.getAttribute('href')!.replace(this._layoutService.config.theme, theme);

    this.replaceThemeLink(newHref, () => {
      this._layoutService.config.theme = theme;
      this._layoutService.onConfigUpdate();
    });
  }

  replaceThemeLink(href: string, onComplete: Function) {
    const id = 'theme-link';
    const themeLink = <HTMLLinkElement>document.getElementById(id);
    const cloneLinkElement = <HTMLLinkElement>themeLink.cloneNode(true);

    cloneLinkElement.setAttribute('href', href);
    cloneLinkElement.setAttribute('id', id + '-clone');

    themeLink.parentNode!.insertBefore(cloneLinkElement, themeLink.nextSibling);

    cloneLinkElement.addEventListener('load', () => {
      themeLink.remove();
      cloneLinkElement.setAttribute('id', id);
      onComplete();
    });
  }
}
