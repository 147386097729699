import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { ToastModule } from 'primeng/toast';

import { AuthService } from '../../services/auth/auth.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PasswordModule,
    ButtonModule,
    InputTextModule,
    ToastModule
  ],
  providers: [MessageService],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent {
  public loginForm: FormGroup;
  private _authService = inject(AuthService);

  public logoPestware = 'https://pestwareapp.com/loginew/img/logo.svg'

  constructor(
    private _messageService: MessageService,
    private _formBuilder: FormBuilder
  ) {
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]]
    });
  }

  public async onSubmit(event: Event): Promise<void> {
    event.preventDefault();

    if (this.loginForm.valid) {
      const isSuccessful = await this._authService.login(this.loginForm.value.email, this.loginForm.value.password);

      if (!isSuccessful) {
        this._messageService.add({
          severity: 'error',
          summary: 'Error',
          detail: 'Hubo un error al iniciar sesión. Comprueba tus credenciales e intenta de nuevo.'
        });

        console.error('Error al iniciar sesión');
      }
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

  public hasErrors(field: string, typeError: string) {
    return this.loginForm.get(field)?.hasError(typeError) && this.loginForm.get(field)?.touched;
  }
}
