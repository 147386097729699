<ng-container>
  <div *ngIf="root && item.visible !== false" class="layout-menuitem-root-text">
    {{ item.label }}
  </div>

  <a
    *ngIf="(!item.routerLink || item.items) && item.visible !== false"
    [attr.href]="item.url"
    (click)="itemClick($event)"
    (mouseenter)="onMouseEnter()"
    [ngClass]="item.class"
    [attr.target]="item.target"
    tabindex="0"
    pRipple
    [pTooltip]="item.label"
    [tooltipDisabled]="!(isSlim && root && !active)"
  >
    <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    <span class="layout-menuitem-text">
      {{ item.label }}
    </span>
    <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
  </a>

  <a
    *ngIf="(item.routerLink && !item.items) && item.visible !== false"
    (click)="itemClick($event)"
    (mouseenter)="onMouseEnter()"
    [ngClass]="item.class"
    [routerLink]="item.routerLink"
    routerLinkActive="active-route"
    [routerLinkActiveOptions]="item.routerLinkActiveOptions || { paths: 'exact', queryParams: 'ignored', matrixParams: 'ignored', fragment: 'ignored' }"
    [fragment]="item.fragment"
    [queryParamsHandling]="item.queryParamsHandling"
    [preserveFragment]="item.preserveFragment!"
    [skipLocationChange]="item.skipLocationChange!"
    [replaceUrl]="item.replaceUrl!"
    [state]="item.state"
    [queryParams]="item.queryParams"
    [attr.target]="item.target"
    tabindex="0"
    pRipple
    [pTooltip]="item.label"
    [tooltipDisabled]="!(isSlim && root)"
  >
    <i [ngClass]="item.icon" class="layout-menuitem-icon"></i>
    <span class="layout-menuitem-text">
      {{ item.label }}
    </span>
    <i class="pi pi-fw pi-angle-down layout-submenu-toggler" *ngIf="item.items"></i>
  </a>

  <ul
    #submenu
    *ngIf="item.items && item.visible !== false" [@children]="submenuAnimation"
    (@children.done)="onSubmenuAnimated($event)"
  >
    <ng-template ngFor let-child let-i="index" [ngForOf]="item.items">
      <li [class]="child.badgeClass">
        <app-menu-item [item]="child" [index]="i" [parentKey]="key"></app-menu-item>
      </li>
    </ng-template>
  </ul>
</ng-container>
